@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap";
body, html {
  color: #777;
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  text-rendering: optimizelegibility !important;
  -webkit-font-smoothing: antialiased !important;
  width: 100% !important;
  height: 100% !important;
}

:root {
  --primary-bgBlue: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}

.bg-blue {
  background-image: var(--primary-bgBlue);
  color: #fff;
}

.custom-dflex {
  justify-content: center;
  display: flex;
}

h2, h3, h4 {
  font-family: Raleway, sans-serif;
}

h2 {
  text-transform: uppercase;
  color: #333;
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 800;
}

h3 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

h4 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

p {
  font-size: 15px;
}

p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}

a {
  color: #608dfd;
  font-weight: 400;
}

a:hover, a:focus {
  color: #608dfd;
  text-decoration: none;
}

ul, ol {
  webkit-padding: 0;
  moz-padding: 0;
  padding: 0;
  list-style: none;
}

hr {
  text-align: center;
  background: #1e7a46;
  border: 0;
  width: 70px;
  height: 2px;
  margin-bottom: 20px;
  position: relative;
}

.section-padding {
  padding: 40px 0;
}

#menu #logo {
  align-items: center;
  display: flex;
  color: #d3664d !important;
}

#logo img {
  width: 60px;
  height: auto;
}

#menu {
  padding: 15px;
  transition: all .8s;
}

#menu.navbar-default {
  background-color: #fff;
  border-color: #e7e7e700;
  box-shadow: 0 0 10px #00000026;
}

#menu a.navbar-brand {
  color: #333;
  text-transform: uppercase;
  font-family: Raleway, sans-serif;
  font-size: 24px;
  font-weight: 700;
}

#menu.navbar-default .navbar-nav > li > a {
  text-transform: uppercase;
  color: #555;
  border-radius: 0;
  margin: 9px 20px 0;
  padding: 8px 2px;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

#menu.navbar-default .navbar-nav > li > a:after {
  content: "";
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  width: 0;
  height: 2px;
  transition: width .2s;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
}

#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background-color: #0000;
}

.navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after {
  content: "" !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  width: 100% !important;
  height: 2px !important;
  transition: width .2s !important;
  display: block !important;
  position: absolute !important;
  bottom: -1px !important;
  left: 0 !important;
}

.navbar-toggle {
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.section-title h2:after {
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  width: 60px;
  height: 4px;
  margin-left: -30px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.section-title p {
  font-size: 18px;
}

.btn-custom {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  border: 0;
  border-radius: 25px;
  margin: 0;
  padding: 14px 34px;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: all .5s linear;
}

.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
  color: #fff;
  background-color: #6372ff;
  background-image: none;
}

.btn:active, .btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  outline: 0;
}

a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline-offset: none;
  outline: none;
}

.intro {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: #e5e5e5 url("hero-section.e86a14f5.jpg") center / cover no-repeat;
  width: 100%;
  padding: 0;
  display: table;
}

.intro .overlay {
  background: #000000c7;
}

.intro h1 {
  color: #fff;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 82px;
  font-weight: 700;
}

.intro h1 span {
  color: #5ca9fb;
  font-weight: 800;
}

.intro p {
  color: #fff;
  margin: 0 auto 60px;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
}

header .intro-text {
  text-align: center;
  padding-top: 350px;
  padding-bottom: 200px;
}

#features {
  background: #f6f6f6;
}

#features i.fa {
  color: #fff;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  padding: 30px 0;
  font-size: 38px;
  transition: all .5s;
  box-shadow: 10px 10px 10px #0000000d;
}

#about h3 {
  font-size: 22px;
}

#about h2 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

#about h2:after {
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  width: 60px;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
}

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  padding: 0;
  list-style: none;
}

#about .about-text li:before {
  content: "";
  color: #5ca9fb;
  padding-right: 8px;
  font-family: FontAwesome;
  font-size: 11px;
  font-weight: 300;
}

#about img {
  background: #fff;
  border-right: 0;
  width: 520px;
  margin-top: 10px;
  box-shadow: 0 0 50px #0000000f;
}

#about p {
  line-height: 24px;
}

#services {
  color: #fff;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}

#services .service-desc {
  margin: 10px 10px 20px;
}

#services h2 {
  color: #fff;
}

#services .section-title h2:after {
  content: "";
  background: #ffffff4d;
  width: 60px;
  height: 4px;
  margin-left: -30px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.service-img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: auto;
  padding: 10px;
  box-shadow: 10px 10px 10px #0000000d;
}

.service-img img {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

#services i.fa {
  color: #fff;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  font-size: 42px;
  box-shadow: 10px 10px 10px #0000000d;
}

#services h3 {
  color: #fff;
  padding: 5px 0;
  font-size: 2.2rem;
  font-weight: 500;
}

#services p {
  color: #fff;
}

#services .service-desc {
  margin-bottom: 40px;
}

.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}

.portfolio-item .hover-bg {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.hover-bg .hover-text {
  text-align: center;
  color: #fff;
  opacity: 0;
  background: linear-gradient(to right, #6372ffcc 0%, #5ca9fbcc 100%);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 30% 0 0;
  transition: all .5s;
  position: absolute;
}

.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  transition: all .3s;
  transform: translateY(100%);
}

.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  transform: translateY(0);
}

.hover-bg:hover .hover-text {
  opacity: 1;
}

#testimonials {
  background: #f6f6f6;
}

#testimonials i {
  color: #e6e6e6;
  margin-bottom: 20px;
  font-size: 32px;
}

.testimonial {
  padding: 20px;
  position: relative;
}

.testimonial-image {
  float: left;
  margin-right: 15px;
}

.testimonial-image, .testimonial-image img {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: block;
}

.testimonial-content {
  position: relative;
  overflow: hidden;
}

.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}

.testimonial-meta {
  color: #666;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

#contact {
  color: #ffffffbf;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}

#contact .section-title {
  margin-bottom: 40px;
}

#contact .section-title p {
  font-size: 16px;
}

#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#contact .section-title h2:after {
  content: "";
  background: #ffffff4d;
  width: 60px;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 30px;
}

#contact h3 {
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}

#contact form {
  padding-top: 20px;
}

#contact .text-danger {
  color: #c03;
  text-align: left;
}

#contact .btn-custom {
  background: none;
  border: 2px solid #fff;
  margin: 30px 0;
}

#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}

label {
  float: left;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

#contact .form-control {
  color: #444;
  -webkit-box-shadow: none;
  box-shadow: none;
  -o-transition: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  transition: none;
  display: block;
}

#contact .form-control:focus {
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
  border-color: #999;
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  color: #777;
}

.form-control:-moz-placeholder {
  color: #777;
}

.form-control::-moz-placeholder {
  color: #777;
}

.form-control:-ms-input-placeholder {
  color: #777;
}

#contact .contact-item {
  margin: 20px 0;
}

#contact .contact-item span {
  color: #fff;
  margin-bottom: 10px;
  display: block;
}

#contact .contact-item i.fa {
  margin-right: 10px;
}

#contact .social {
  text-align: center;
  border-top: 1px solid #ffffff26;
  margin-top: 50px;
  padding-top: 50px;
}

#contact .social ul li {
  margin: 0 20px;
  display: inline-block;
}

#contact .social i {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  font-size: 22px;
  transition: all .3s;
}

#contact .social i:hover {
  color: #608dfd;
  background: #fff;
}

#footer {
  background: #f6f6f6;
  padding: 30px 0;
}

#footer p {
  color: #888;
  font-size: 14px;
}

#footer a {
  color: #608dfd;
}

#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

@media (width <= 768px) {
  #about img {
    margin: 50px 0;
  }
}

.custom-white-btn {
  background-color: #fff;
  border-radius: 25px;
}

#features .feature {
  margin: 40px 10vw;
  padding: 40px;
  box-shadow: 0 0 5px 1px #0000001a;
}

.footer-logo {
  margin-top: 15px;
}

.footer-logo img {
  width: 70px;
}

.footer-logo h3 {
  color: #d3664d !important;
}

#footer .social a {
  color: #888;
  font-size: 2rem;
}

.inner-footer {
  text-align: left;
  justify-content: space-between;
  display: flex;
}

@media (width <= 756px) {
  .inner-footer {
    text-align: center;
    padding: 15px;
    display: block;
  }
}
/*# sourceMappingURL=index.b2aae823.css.map */
